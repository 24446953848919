import sbpActiontypes from "../../actiontypes/sbpActiontypes";

const insurerLoadStart = () => ({
  type: sbpActiontypes.INSURER_LOAD_START,
});
/* istanbul ignore next  */
const insurerLoadSuccess = (data) => ({
  type: sbpActiontypes.INSURER_LOAD_SUCCESS,
  payload: data,
});
/* istanbul ignore next  */
const insurerLoadData = (data) => ({
  type: sbpActiontypes.INSURER_LOAD_LIST,
  payload: data.data,
});

const insurerLoadErrors = (errmsg) => ({
  type: sbpActiontypes.INSURER_LOAD_ERRORS,
  payload: errmsg,
});
const insurerListLoadStart = () => ({
  type: sbpActiontypes.INSURER_LIST_LOAD_START,
});
export default {
  insurerLoadStart,
  insurerLoadSuccess,
  insurerLoadErrors,
  insurerLoadData,
  insurerListLoadStart,
};
