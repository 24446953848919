import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory, useNavigate } from "react-router";
import TimeoutModal from "../common/Modal/TimeOutmodal";
import Cookies from "js-cookie";

import sbpServices from "../services/sbpServices";
let SESSION_IDEL_MINUTES = 14;
let countdownInterval;
const AutoLagoutTimer = (props) => {
  const { ComposedClass } = props;
  const [showModal, setshowModal] = useState(false);
  const navigate = useNavigate();
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const [minuts, setMinutes] = useState(14);
  const handleOnIdle = (event) => {
    // SHOW YOUR MODAL HERE AND LAGOUT

    //setshowModal(true);
    const delay = 1000 * 5;

    SESSION_IDEL_MINUTES = setTimeout(() => {
      let countDown = 60;
      let minDown = 14;
      setshowModal(true);
      setTimeoutCountdown(countDown);
      countdownInterval = setInterval(() => {
        if (minDown > 0) {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            countDown = 60;
            setMinutes(--minDown);
            setTimeoutCountdown(countDown);
          }
        } else {
          handleLogout();
          countDown = 0;
          minDown = 0;
        }
      }, 1000);
    }, delay);
  };

  const clearSessionTimeout = () => {
    clearTimeout((SESSION_IDEL_MINUTES = 14));
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };
  const handleLogout = () => {
    setshowModal(false);
    //clearSessionInterval();
    clearSessionTimeout();
    Cookies.remove("sessiontoken");
    Cookies.remove("username");
    Cookies.remove("useremailid");
    Cookies.remove("TokenExpTime");
    Cookies.remove("sessionId");
    Cookies.remove("accessToken");
    Cookies.remove("accessTokenExpiresIn");
    Cookies.remove("appusertype");
    // logout();
    // navigate("/");
    window.location.assign("/login");
  };

  const handleContinue = async () => {
    setshowModal(false);
    // clearSessionInterval();
    // clearSessionTimeout();
    // Cookies.remove("Token");
    // Cookies.remove("TokenExpTime");
    // const AuthData = {
    //   ClientId: process.env.REACT_APP_TOKEN_CLIENT_ID,
    // };
    // await sbpServices
    //   ._Jwt_AuthToken(AuthData)
    //   .then((res) => {
    //     if (res.status === 200 || res.status === 201) {
    //       const tokenkey = res.data.access_token.access_token;
    //       const tokenexpire = res.data.access_token.expires_in;
    //       Cookies.set("Token", tokenkey);
    //       Cookies.set("TokenExpTime", tokenexpire);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };
  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div>
      {" "}
      <ComposedClass />{" "}
      <TimeoutModal
        showModal={showModal}
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        minuts={minuts}
      />
    </div>
  );
};

export default AutoLagoutTimer;
