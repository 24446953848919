import actiontypes from "../../actiontypes/sbpActiontypes";

const caseCommDetailsLoadStart = () => ({
  type: actiontypes.CASECOMM_DETAILS_LOAD_START,
});
/* istanbul ignore next  */
const caseCommDetailsLoadSuccess = (data) => ({
  type: actiontypes.CASECOMM_DETAILS_LOAD_SUCCESS,
  payload: data,
});

const caseCommDetailsLoadError = (errmsg) => ({
  type: actiontypes.CASECOMM_DETAILS_LOAD_ERRORS,
  payload: errmsg,
});

const caseCommMemberLoadStart = () => ({
  type: actiontypes.CASECOMM_MEM_LOAD_START,
});
/* istanbul ignore next  */
const caseCommMemberLoadSuccess = (data) => ({
  type: actiontypes.CASECOMM_MEM_LOAD_SUCCESS,
  payload: data,
});

const caseCommMemberLoadError = (errmsg) => ({
  type: actiontypes.CASECOMM_MEM_LOAD_ERRORS,
  payload: errmsg,
});

const caseCommMesHistLoadStart = () => ({
  type: actiontypes.CASECOMM_MES_HIST_LOAD_START,
});
/* istanbul ignore next  */
const caseCommMesHistLoadSuccess = (data) => ({
  type: actiontypes.CASECOMM_MES_HIST_LOAD_SUCCESS,
  payload: data,
});

const caseCommMesHistLoadError = (errmsg) => ({
  type: actiontypes.CASECOMM_MES_HIST_LOAD_ERRORS,
  payload: errmsg,
});

export default {
  caseCommDetailsLoadStart,
  caseCommDetailsLoadSuccess,
  caseCommDetailsLoadError,
  caseCommMemberLoadStart,
  caseCommMemberLoadSuccess,
  caseCommMemberLoadError,
  caseCommMesHistLoadStart,
  caseCommMesHistLoadSuccess,
  caseCommMesHistLoadError
};
