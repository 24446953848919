import React, { Fragment, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { _submitPartyInformation } from "../../redux/actions/SBP/partyInfoActions";
import { useDispatch } from "react-redux";
import Recaptcha from "react-google-invisible-recaptcha";
import sbpServices from "../../services/sbpServices";
import CommonError from "../../common/Alert/CommonError";
import UncaughtError from "../../common/Alert/UncaughtError";
const CaseFirmMismatch = (props) => {
  const { partydata, fieldname, type, attorneytype, attorneytext } = props;
  let dispatch = useDispatch();
  const refRecaptcha = useRef(null);
  const [searchfield, setSearchField] = useState("");
  const [errSearchfield, seterrSearchfield] = useState("");
  const [searchfieldtext, setSearchFieldtext] = useState(fieldname);
  const [haserror, setHasError] = useState("");
  let [apicount, setApiCount] = useState(0);
  /* istanbul ignore next */
  const _onfindCliam_Handler = async () => {
    setSearchFieldtext("");
    setHasError("");
    sessionStorage.removeItem("firmname");
    /* istanbul ignore else */
    if (searchfield === null || searchfield === "") {
      seterrSearchfield(`Please enter the ${attorneytext && attorneytext} !!`);
    } else {
      setSearchFieldtext(searchfield);
      setApiCount(++apicount);
      sessionStorage.setItem("firmname", searchfield);
      await dispatch(
        _submitPartyInformation(
          {
            claimno: "",
            firmname: searchfield,
            // .replace(/[^\w\s]/gi, "")
            // .replace(/\s+/g, ""),
            partytype: type,
            attorneytype: attorneytype,
          },
          partydata
        )
      );

      // setSearchField("");
    }
  };
  /* istanbul ignore if */
  if (apicount === 3) {
    sessionStorage.setItem("apicallcount", apicount);
  }
  const _onsearch_Handler = (e) => {
    /* istanbul ignore next */
    seterrSearchfield("");
    /* istanbul ignore next */
    setSearchField(e.target.value);
    /* istanbul ignore next */
    setHasError("");
  };

  return (
    <div data-testid="partymismatch">
      <Fragment>
        <Alert variant="warning">
          {" "}
          {attorneytext && attorneytext} not found.
        </Alert>
        {sessionStorage.getItem("apicallcount") === "3" ? (
          <h6 style={{ color: "red" }}>
            {`We’re having trouble matching your ${
              attorneytext && attorneytext
            } to this case. Please use one of the contact methods in the Tip box below to let us know who you are.`}
          </h6>
        ) : (
          <p>
            {`You'll need to provide a matching ${
              attorneytext && attorneytext
            } to see additional information.`}
          </p>
        )}

        <div className="row">
          <div className="col-md-6">
            {" "}
            <Fragment>
              <p>
                You entered{" "}
                <strong>
                  {sessionStorage.getItem("firmname") === "" ||
                  sessionStorage.getItem("firmname") === null
                    ? fieldname
                    : sessionStorage.getItem("firmname")}
                  {/* {fieldname} */}
                </strong>
              </p>
              <div className="row">
                <div className="form-group col-12 col-md-8">
                  <label className="font-weight-bold" htmlFor="FirmName">
                    {attorneytext && attorneytext}
                  </label>

                  <Form.Control
                    id="FirmName"
                    type="text"
                    className="form-control form-control-sm"
                    data-testid="firmsearch"
                    autoComplete="off"
                    onChange={_onsearch_Handler}
                    onPaste={(e) => {
                      /* istanbul ignore next */
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      /* istanbul ignore next */
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <span className="errmsg">{errSearchfield}</span>

                  {/* <Form.Text className="small mt-1 mb-0">
                    Firm or individual attorney name{" "}
                  </Form.Text> */}
                </div>
              </div>
              <Button
                variant="primary"
                className="btn btn-success btn-sm"
                type="submit"
                data-testid="btnnext"
                disabled={
                  sessionStorage.getItem("apicallcount") === "3" && true
                }
                onClick={_onfindCliam_Handler}
              >
                Find
              </Button>{" "}
              {haserror && <UncaughtError />}
            </Fragment>
          </div>
          {sessionStorage.getItem("apicallcount") === "3" && (
            <div
              className="col-md-6"
              style={{ border: "1px groove black", maxWidth: "550px" }}
            >
              <small>
                Tip: You can find the {attorneytext && attorneytext} format
                expected, by checking a letter you’ve received from MultiPlan.{" "}
              </small>{" "}
              <br />
              <small> Or you can contact the call center at 866-223-9974</small>
              <br />
              <small>
                Or You can send us a message using the Send a Response message
                box above. It typically takes 3 – 5 days to process the
                information.
              </small>
            </div>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default CaseFirmMismatch;
