import sbpServices from "../../../services/sbpServices";
import actioncreator from "./caseInformationActionCreater";

export const _getCaseInfomation =
  (_patientlastname, _caseid) => async (dispatch) => {
    try {
      dispatch(actioncreator.caseInfoLoadStart());
      const res = await sbpServices
      ._getcaseInfo(_patientlastname, _caseid)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(actioncreator.caseInfoLoadSuccess(res.data));
      }
      if (res.status === 204) {       
        dispatch(actioncreator.caseInfoLoadError(res));
      }
    } catch (err) {
      dispatch(actioncreator.caseInfoLoadError(err.response));
    }
  };

/* istanbul ignore next */
export const _getSesionwith_MFA =
  (verifydata) => async (dispatch) => {
    try {
      /* istanbul ignore next */
      dispatch(actioncreator.caseInfoLoadStart());
      /* istanbul ignore next */
      const res = await sbpServices
        ._verify_MFA(verifydata)
        .then((result) => {
          /* istanbul ignore next */          
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200 && res.data.statusCode === 200) {
        if(res.data.userType === "insurance" || res.data.userType === "attorney" )
        {
          dispatch(actioncreator._getsession_mfa(res.data));
        }
        else
        {
          window.location.assign("/page-unathorized");
        }
      }
      else
      {
        dispatch(actioncreator._getsession_mfa_error(res.data));
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(actioncreator.caseInfoLoadError(err.response));
    }
  };
/* istanbul ignore next */
export const _getSesion_Logout = () => async (dispatch) => {
  /* istanbul ignore next */ dispatch(actioncreator.SessionOutClear());
};
