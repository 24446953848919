import sbpActiontypes from "../../actiontypes/sbpActiontypes";

const initialstate = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  patientdata: {},
};
const patientInfoReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case sbpActiontypes.PATIENTINFO_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null,
      };
    case sbpActiontypes.PATIENTINFO_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        patientdata: payload.data.query,
      };
    case sbpActiontypes.PATIENTINFO_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload.status !== 204 ? payload.status : null,
        statuscode: payload.status, //404,500
      };
    case sbpActiontypes.PATIENTINFO_CLEAR:
      return {
        ...state,
        isloading: false,
        statuscode: null, //404,500
      };
    default:
      return state;
  }
};

export default patientInfoReducer;
