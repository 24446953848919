import sbpActiontypes from "../../actiontypes/sbpActiontypes";

const initialstate = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  insurerName: null,
  patientlastname: null,
  insurerlist: [],
  insurerloader: false,
};
const insurerReducer = (state = initialstate, { type, payload }) => {
  switch (type) {
    case sbpActiontypes.INSURER_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null,
insurerloader: false,
      };
    case sbpActiontypes.INSURER_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        patientlastname: payload.data.query.patientLastName,
        insurerName: payload.data.query.insurer,
insurerloader: false,
      };
    case sbpActiontypes.INSURER_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload.status !== 204 ? payload.status : null,
        statuscode: payload.status, //404,500
        insurerloader: false,
      };
    case sbpActiontypes.INSURER_LOAD_LIST:
      return {
        ...state,
        isloading: false,
        insurerlist: payload,
        insurerloader: false,
      };
    case sbpActiontypes.INSURER_LIST_LOAD_START:
      return {
        ...state,
        insurerloader: true,
        statuscode: null,
        errmsg: null,
        isloading: false,
      };
    default:
      return state;
  }
};

export default insurerReducer;
