import sbpActiontypes from "../../actiontypes/sbpActiontypes";

const patientInfoLoadStart = () => ({
  type: sbpActiontypes.PATIENTINFO_LOAD_START,
});

const patientInfoLoadSuccess = (data) => ({
  type: sbpActiontypes.PATIENTINFO_LOAD_SUCCESS,
  payload: data,
});

const patientInfoLoadErrors = (errmsg) => ({
  type: sbpActiontypes.PATIENTINFO_LOAD_ERRORS,
  payload: errmsg,
});

export default {
  patientInfoLoadStart,
  patientInfoLoadSuccess,
  patientInfoLoadErrors,
};
