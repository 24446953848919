import actiontypes from "../../actiontypes/sbpActiontypes";

const initialState = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  caseCommId: null,  
  cceId: null,
  clientId: null,
  from: null,
  to: null,
  cc: null,
  subject: null,
  message: null,
  signature: null,
  attachements: null
};

const caseCommDetailsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actiontypes.CASECOMM_DETAILS_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null,
      };
    case actiontypes.CASECOMM_DETAILS_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        cceId: payload.data.cceId,
        clientId: payload.data.clientId,
        from: payload.data.from,
        to: payload.data.to,
        cc: payload.data.cc,
        subject: payload.data.subject,
        message: payload.data.message,
        caseCommId: payload.data.caseCommId,
        signature: payload.data.signature,
        attachements: payload.data.attachements
      };
    case actiontypes.CASECOMM_DETAILS_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload.status !== 204 ? payload.status : null,
        statuscode: payload.status, //404,500
      };
    default:
      return state;
  }
};

export default caseCommDetailsReducer;
