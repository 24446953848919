import sbpServices from "../sbpServices";
//import { confirmAlert } from "react-confirm-alert"; // Import
//import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// export function TimeoutPopup() {
//   return confirmAlert({
//     title: "Alert",
//     message: "Session has expired.Please click here to ok button.",
//     buttons: [
//       {
//         label: "ok",
//         onClick: () => expireToken(),
//       },
//     ],
//     closeOnEscape: false,
//     closeOnClickOutside: false,
//   });
//   //return "";
// }

export async function ExpireToken() {
  /// let navigate = useNavigate();
  let sid = Cookies.get("sessionId");
  let refreshToken = Cookies.get("refreshToken");
  if (sid !== "null" && sid !== "undefined" && sid !== "") {
    await sbpServices
      ._refersh_session(sid, refreshToken)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Cookies.remove("TokenExpTime");
          Cookies.remove("accessToken");
          Cookies.remove("accessTokenExpiresIn");
          Cookies.remove("refreshToken", res.data.refreshToken);

          Cookies.set("TokenExpTime", res.data.sessionTokenExpireIn);
          Cookies.set("accessToken", res.data.accessToken);
          Cookies.set("", res.data.refreshToken);
          Cookies.set("accessTokenExpiresIn", res.data.accessTokenExpiresIn);
        }
      })
      .catch((err) => {        
        Cookies.remove("sessiontoken");
        Cookies.remove("username");
        Cookies.remove("useremailid");
        Cookies.remove("TokenExpTime");
        Cookies.remove("sessionId");
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("accessTokenExpiresIn");
        Cookies.remove("appusertype");

        window.location.assign("/login");
      });
  } else {
    Cookies.remove("sessiontoken");
    Cookies.remove("username");
    Cookies.remove("useremailid");
    Cookies.remove("TokenExpTime");
    Cookies.remove("sessionId");
    Cookies.remove("accessToken");
    Cookies.remove("accessTokenExpiresIn");
    Cookies.remove("appusertype");
    window.location.assign("/login");
  }
}
