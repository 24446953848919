import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Cookie from "js-cookie";

import LoginContent from "../../common/Content/LoginContent";
import sbpServices from "../../services/sbpServices";
import { LoginAlert } from "../../common/Alert/LoginAlert";
import Loader from "../../common/Loader/Loading";

const ReactivationLinkEmail = () => {
  let navigate = useNavigate(); //redirect to next page
  const dispatch = useDispatch(); //calling redux actions
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailtxt, setEmailtxt] = useState("");
  const [loading, setIsloading] = useState(false);
  const [exError, setExError] = useState("");
  const [extext, setExtext] = useState("");
  const [alertflag, setAlertFlag] = useState(false);

  useEffect(() => {}, []);

  const passwordreset_Hander = (e) => {
    /* istanbul ignore next */
    e.preventDefault();
    /* istanbul ignore next */
    setIsloading(true);
    /* istanbul ignore next */
    setExError("");
    setEmailtxt("");
    sbpServices
      ._getReactivationLink(email)
      .then((res) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          /* istanbul ignore next */ setAlertFlag(true);
          /* istanbul ignore next */ setEmailtxt(email);
          /* istanbul ignore next */ setExError("");
          /* istanbul ignore next */ setEmail("");
        } else {
          /* istanbul ignore next */ setExError(res.data.statusText);
        }
      })
      .catch((err) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (err.response.status === 404) {
          /* istanbul ignore next */
          setExError(
            "Email address doesn’t exists. Please enter valid email address."
          );
        } else {
          /* istanbul ignore next */
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        }
      });
  };

  const resetemail_handler = (e) => {
    e.preventDefault(); /* istanbul ignore next */
    setExError(""); /* istanbul ignore next */
    /* istanbul ignore next */ setAlertFlag(false);
    /* istanbul ignore next */ setExtext("");
    /* istanbul ignore next */ setEmail(e.target.value);
    /* istanbul ignore next */ setEmailtxt("");
  };
  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2 createuser">
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Reactivation Request</h1>
            {alertflag && (
              <LoginAlert
                type={"success"}
                errmsg="Email sent successfully!!"
                formattext={`Email has sent to ${emailtxt} with instructions on resetting your password.`}
              />
            )}
            {exError !== "" ? (
              <LoginAlert
                type={"danger"}
                errmsg={exError}
                formattext={extext}
              />
            ) : null}

            <Form
              className="flow-sm"
              onSubmit={passwordreset_Hander}
              autoComplete="off"
            >
              <Form.Label className="font-weight-bold" id="email">
                Email
              </Form.Label>
              <Form.Group>
                <Form.Control
                  id="email"
                  data-testid="email"
                  type="email"
                  required
                  aria-labelledby="email"
                  placeholder="Email"
                  className="form-control form-control-sm"
                  value={email}
                  onChange={resetemail_handler}
                />
              </Form.Group>

              {loading && loading ? (
                <Loader />
              ) : (
                <div>
                  <Button
                    variant="primary"
                    className="btn btn-success btn-sm g-recaptcha"
                    type="submit"
                    //style={{ float: "right" }}
                    data-testid="btnnext"
                    //onClick={passwordreset_Hander}
                  >
                    Get via Email
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <p className="mb-1">Don't worry,happens to the best of us.</p>
          <p>Tell us where to Send Reactivation token.</p>

          <p>
            Or click here to <Link to="/"> Sign in</Link>
          </p>
          <br />
          <br />
          <p className="loginbold mb-1">Having trouble signing in?</p>
          <p className="">
            Contact us{" "}
            <a
              href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
            >
              via email
            </a>{" "}
            or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
          </p>
        </div>
      </div>
    </>
  );
};

export default ReactivationLinkEmail;
