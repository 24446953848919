import actiontypes from "../../actiontypes/sbpActiontypes";

const caseInfoLoadStart = () => ({
  type: actiontypes.CASEINFO_LOAD_START,
});
/* istanbul ignore next  */
const caseInfoLoadSuccess = (data) => ({
  type: actiontypes.CASEINFO_LOAD_SUCCESS,
  payload: data,
});

const caseInfoLoadError = (errstatus) => ({
  type: actiontypes.CASEINFO_LOAD_ERRORS,
  payload: errstatus  
});
/* istanbul ignore next  */
const PatientInfoLoadClear = () => ({
  type: actiontypes.PATIENTINFO_CLEAR,
});
const _getsession_mfa = (data) => ({
  type: actiontypes.GET_SESSION_MFA,
  payload: data,
});
const _getsession_mfa_error = (data) => ({
  type: actiontypes.GET_SESSION_MFA_ERRORS,
  payload: data,
});

const SessionOutClear = (data) => ({
  type: actiontypes.SESSION_CLEAR,
  payload: data,
});
export default {
  caseInfoLoadStart,
  caseInfoLoadSuccess,
  caseInfoLoadError,
  PatientInfoLoadClear,
  _getsession_mfa,
  SessionOutClear,
  _getsession_mfa_error
};
