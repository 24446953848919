import sbpServices from "../../../services/sbpServices";
import actioncreator from "./caseCommunicationActionCreater";

export const _getCaseCommuncationMemberDetails =
  (_caseCommunicationId) => async (dispatch) => {
    try {
      dispatch(actioncreator.caseCommMemberLoadStart());
      const res = await sbpServices
        ._getCaseCommuncationMemberDetails(_caseCommunicationId)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        //dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(actioncreator.caseCommMemberLoadSuccess(res.data));
      }
      if (res.status === 204) {       
        dispatch(actioncreator.caseCommMemberLoadError(res));
      }
    } catch (err) {
      dispatch(actioncreator.caseCommMemberLoadError(err.message));
    }
  };

export const _getCaseCommuncationDetails =
  (_caseCommunicationId,_cceid,_userEmailId) => async (dispatch) => {
    try {
      dispatch(actioncreator.caseCommDetailsLoadStart());
      const res = await sbpServices
        ._getCaseCommuncationDetails(_caseCommunicationId,_cceid,_userEmailId)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        //dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(actioncreator.caseCommDetailsLoadSuccess(res.data));
      }
      if (res.status === 204) {       
        dispatch(actioncreator.caseCommDetailsLoadError(res));
      }
    } catch (err) {
      dispatch(actioncreator.caseCommDetailsLoadError(err.response));
    }
  };

  export const _getCaseCommuncationMessageHistory =
  (_caseCommunicationId,_userEmailId) => async (dispatch) => {
    try {
      dispatch(actioncreator.caseCommMesHistLoadStart());
      const res = await sbpServices
        ._getCaseCommuncationMessageHistory(_caseCommunicationId,_userEmailId)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next  */
      if (res.status === 200) {
        //dispatch(actioncreator.PatientInfoLoadClear());
        dispatch(actioncreator.caseCommMesHistLoadSuccess(res.data));
      }
      if (res.status === 204) {       
        dispatch(actioncreator.caseCommMesHistLoadError(res));
      }
    } catch (err) {
      dispatch(actioncreator.caseCommMesHistLoadError(err.message));
    }
  };