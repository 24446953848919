import { combineReducers } from "redux";
import caseinfoReducer from "./SBP/caseinformationReducer";
import insurerReducer from "./SBP/InsurerReducer";
import partyInfoReducer from "./SBP/partyInfoReducer";
import patientInfoReducer from "./SBP/patientInfoReducer";
import caseCommMesHistReducer from "./SBP/caseCommunicationMesHistReducer";
import caseCommMemberReducer from "./SBP/caseCommunicationMemberReducer";
import caseCommDetailsReducer from "./SBP/caseCommunicationDetailsReducer";

const rootReducers = combineReducers({
  caseinfo: caseinfoReducer,
  insurerinfo: insurerReducer,
  patientinfo: patientInfoReducer,
  partyinfo: partyInfoReducer,
  casecommdetails: caseCommDetailsReducer,
  casecommhist: caseCommMesHistReducer,
  casecommmember: caseCommMemberReducer
});

export default rootReducers;
