// import * as React from 'react';
import React, { Fragment, useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader/Loading";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import Cookies from "js-cookie";
import makeStyles from "@mui/styled-engine-sc"

import { _getCaseCommuncationDetails, _getCaseCommuncationMessageHistory } from "../../redux/actions/SBP/caseCommunicationActions";

const MessageHistoryGrid = ({ messageHistList }) => {
  const dispatch = useDispatch(); //calling redux actions  
  const [sentEmailsList, setSentEmailsList] = useState([]);
  const [receivedEmailsList, setReceivedEmailsList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const useremailid = Cookies.get("useremailid");

  useEffect(() => {
    if (messageHistList.statuscode && messageHistList.statuscode === 200) {
      setSentEmailsList(messageHistList.histlist.sentEmails);
      setReceivedEmailsList(messageHistList.histlist.receivedEmails);
    }
    console.log("useeffect", messageHistList);
  }, [messageHistList.statuscode]);

  const handlemessageclick = (params) => {
    dispatch(_getCaseCommuncationDetails(params.row.caseCommId, params.row.cce_id, useremailid));
  }

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  const tabs = [
    "Received",
    "Sent",
  ];

  const getRowId = (row) => row.id;

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },    
    {
      field: 'date',
      headerName: 'Date',    
      type: 'date',
      width: 200,
      editable: true,
      valueFormatter:(params)=>new Date(params.value).toLocaleDateString()
    },
    {
      field: 'message',
      headerName: 'Message',      
      width: 400,
      editable: true,
    },
    {
      field: 'from',
      headerName: 'From',      
      width: 250,
      editable: true,
    }
    //,
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  return (
    <div>      <dl className="row mt-3">
      <dt className="col-md-3" data-testid="CaseManager">
        Message History
      </dt>
    </dl>
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={index === activeTab ? "active" : ""}
          >
            {tab}
          </button>
        ))}
      </div>
      <br/>
      <div className="tab-content">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={index === activeTab ? "active" : "hidden"}
          >
           
      <Box sx={{ height: 400, width: '100%', '.MuiDataGrid-columnHeaderTitle':{ fontWeight:'bold'} }}>
        <DataGrid
          rows={tab == "Received" ? receivedEmailsList : sentEmailsList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          getRowId={getRowId}
          onRowClick={handlemessageclick}
          disableRowSelectionOnClick
        />
      </Box>
      <br/>
      <br/>
      </div>
        ))}
      </div>
    </div>
  );
};


export default MessageHistoryGrid;