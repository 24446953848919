import actiontypes from "../../actiontypes/sbpActiontypes";

const initialState = {
  isloading: false,
  statuscode: null,
  errmsg: null,
  isInfoPresent: false,  
  caseId: null,
  insurer: null,
  patientLastName: null,
  zip: null,
  dobDate: null,
  groupId: null,
  memberId: null,
  party: null,
  attorneyType: null,
  attorneyName: null,
  claimNumber: null
};

const caseCommMemberReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actiontypes.CASECOMM_MEM_LOAD_START:
      return {
        ...state,
        isloading: true,
        statuscode: null,
        errmsg: null
      };
    case actiontypes.CASECOMM_MEM_LOAD_SUCCESS:
      return {
        ...state,
        isloading: false,
        statuscode: payload.statusCode, //200
        isInfoPresent: payload.data.isInfoPresent,
        caseId: payload.data.caseId,
        insurer: payload.data.insurer,
        patientLastName: payload.data.patientLastName,
        zip: payload.data.zip,
        dobDate: payload.data.dobDate,
        groupId: payload.data.groupId,
        memberId: payload.data.memberId,
        party: payload.data.party,
        attorneyType: payload.data.attorneyType,
        attorneyName: payload.data.attorneyName,
        claimNumber: payload.data.claimNumber
      };
    case actiontypes.CASECOMM_MEM_LOAD_ERRORS:
      return {
        ...state,
        isloading: false,
        errmsg: payload,
        statuscode: payload.status, //404,500
      };
    default:
      return state;
  }
};

export default caseCommMemberReducer;
