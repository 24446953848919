import axios from "axios";
import Cookies from "js-cookie";
//import { timeoutPopup, expireToken } from "../api/Tokenapi";
import { ExpireToken } from "../api/Tokenapi";
const apiClient = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json",
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookies.get("sessiontoken");
      const accessToken = Cookies.get("accessToken");
      if (token) {
        // Popup For Token Expire

        let tokenMS = Cookies.get("TokenExpTime");
        let loginName = Cookies.get("username");
        let userType = Cookies.get("appusertype");
        let existingTime = new Date(
          new Date().getTime() + tokenMS * 1000 - 120000
        );
        //let existingTime = new Date( new Date().getTime()+ 60000);
        let interval = setInterval(() => {
          var presentDate = new Date();
          if (existingTime <= presentDate.getTime()) {
            // timeoutPopup(); //call alert here
            // const answer = window.confirm("Session expired");
            // if (answer) {
            //   console.log("swapnil");
            //   ExpireToken();
            // }
            ExpireToken();
            clearInterval(interval);
          } else console.log("Still valid");
        }, 60000);
        // End Popup Code
        config.headers.common["Authorization"] = "Bearer " + accessToken;        
        config.headers.common["loginName"] =
          loginName !== "null" || loginName !== "undefined" || loginName !== ""
            ? loginName
            : null;
        config.headers.common["userType"] =
          userType !== "null" || userType !== "undefined" || userType !== ""
            ? userType
            : null;
      } 
      config.headers["Ocp-Apim-Subscription-Key"] = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY_SYSTEM;
      config.headers["Ocp-Apim-Trace"] = true;
      config.headers["Allowed-Frontend"] = "SBP";
      config.headers["SBP-APP-ID"] = Cookies.get("SBP-APP-ID");;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      // Access Token was expired
      if (err.response.status === 401) {
        try {
          // await expireToken()
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
      return Promise.reject(err);
    }
  );
  return axiosInstance;
};

export default apiClient;
