import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PatientInformationContent from "../../common/Content/PatientInformationContent";
import PatientInfoIcon from "../../common/Tooltip/PatientInfoIcon";
import {
  _onlyAlphaNumric,
  _onlyNumric,
  formatPhoneNumber,
  _AlphaNumric_Dot_dash,
} from "../../common/Validation/validation";
import { _patientInformation } from "../../redux/actions/SBP/patientInformationActions";
import { useDispatch, useSelector } from "react-redux";
import AlertValidation from "../../common/Alert/AlertValidation";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import Loader from "../../common/Loader/Loading";
import CommonError from "../../common/Alert/CommonError";
const PatientInformation = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const location = useLocation();
  //const refRecaptcha = useRef(null);
  const [patientInfomation, setPatientInfomation] = useState({
    dobDate: "",
    zip: "",
    memberId: "",
    groupId: "",
  });
  const [alertFlag, setAlertflag] = useState(false);
  const [haserror, setHasError] = useState("");
  const [chkFieldsValidaion, setchkFieldsValidaion] = useState("");
  //selector values from redux
  const { isloading, errmsg, statuscode, patientdata } = useSelector(
    (state) => state.patientinfo
  );
  const { caseId, patientlastname } = useSelector((state) => state.caseinfo);

  /* istanbul ignore else */
  useEffect(() => {
    /* istanbul ignore if */
    setHasError("");
    const chk = Object.values(patientInfomation).filter((item) => item !== "");
    if (statuscode && statuscode === 200 && chk.length > 1) {
      /* istanbul ignore next */
      navigate(`/party-verifier`, {
        state: {
          caseId: location.state.caseId,
          patientlastname: location.state.patientlastname,
          patientInfomation: patientdata,
          insurer: "",
        },
      });
    } /* istanbul ignore if */ else if (statuscode && statuscode === 204) {
      /* istanbul ignore next */ setAlertflag(true);
    }
  }, [statuscode]);
  const onSubmit_Handler = (e) => {
    e.preventDefault();
    /* istanbul ignore next */
    setHasError("");
    setAlertflag(false);
    const chkvalidation = Object.values(patientInfomation).filter(
      (item) => item !== ""
    );

    /* istanbul ignore if */
    if (chkvalidation.length > 1) {
      dispatch(
        _patientInformation(patientInfomation, { 
          caseId: caseId !== null ? caseId : location.state.caseid,
          patientlastname:
            patientlastname !== null
              ? patientlastname
              : location.state.patientlastname,
          insurerName: "",
        })
      );
    } else {
      /* istanbul ignore next */
      setchkFieldsValidaion("Please provide any two fields!!");
    }
  };

  const updateFields = (e) => {
    setHasError("");
    setchkFieldsValidaion("");
    setAlertflag(false);
    setPatientInfomation({
      ...patientInfomation,
      [e.target.name]: e.target.value,
    });
  };
  // console.log("patinet info", patientInfomation);
  return (
    <div>
      <div className="row d-flex justify-content-between mt-2">
        {haserror && <CommonError errormsg={haserror.message} />}
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-4">Patient Information</h1>

            {errmsg && <ErrorValidation errormsg={errmsg} type="danger" />}
            {alertFlag && <AlertValidation type="danger" />}
            <Form
              className="flow-sm"
              onSubmit={onSubmit_Handler}
              autoComplete="off"
            >
              <PatientInfoIcon displaytext="two" />
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Date of Birth
                </Form.Label>
                <Form.Control
                  id="patient-dob"
                  data-testid="patient-dob"
                  type="date"
                  name="dobDate"
                  onChange={updateFields}
                  value={patientInfomation.dobDate}
                  className="form-control form-control-sm"
                  max={new Date().toISOString().slice(0, 10)}
                />
                <Form.Text className="small mt-1 mb-0">
                  Examples: 01/12/1970, 1/12/1970{" "}
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold"> Zip Code</Form.Label>
                <Form.Control
                  id="patient-zip"
                  data-testid="patient-zip"
                  type="text"
                  maxLength="5"
                  minLength="5"
                  name="zip"
                  onKeyPress={(e) => _onlyNumric(e)}
                  onChange={updateFields}
                  className="form-control form-control-sm"
                  value={formatPhoneNumber(patientInfomation.zip)}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold"> Member ID</Form.Label>
                <Form.Control
                  id="patient-memberid"
                  data-testid="patient-memberid"
                  type="text"
                  name="memberId"
                  onChange={updateFields}
                  onKeyPress={(e) => _AlphaNumric_Dot_dash(e)}
                  className="form-control form-control-sm"
                  value={patientInfomation.memberId}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold"> Group #</Form.Label>
                <Form.Control
                  id="patient-group"
                  data-testid="patient-group"
                  type="text"
                  name="groupId"
                  onKeyPress={(e) => _AlphaNumric_Dot_dash(e)}
                  onChange={updateFields}
                  value={patientInfomation.groupId}
                  className="form-control form-control-sm"
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                <span className="errmsg">{chkFieldsValidaion}</span>
              </Form.Group>

              {isloading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm"
                  type="submit"
                  data-testid="btnnext"
                >
                  Next
                </Button>
              )}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <PatientInformationContent value="two" />
        </div>
      </div>
    </div>
  );
};

export default PatientInformation;
