import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import Loader from "../../common/Loader/Loading";
import sbpServices from "../../services/sbpServices";
import CommonError from "../../common/Alert/CommonError";
import Cookies from "js-cookie";
import { _submitPartyInformation } from "../../redux/actions/SBP/partyInfoActions";
import { useEffect } from "react";
import AlertValidation from "../../common/Alert/AlertValidation";
const PartyVerifier = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const dispatch = useDispatch();
  const [radioparty, setRadioParty] = useState();
  const [rbtattorneyfirmname, setrbtAttorneyfirmname] = useState("");
  const [rbtattorneytext, setrbtAttorneytext] = useState("");
  const [partyvalues, setPartyValues] = useState({
    firmname: "",
    claimno: "",
  });
  const [alertFlag, setAlertflag] = useState(false);
  const [errParty, setErrParty] = useState("");

  const { isloading, errmsg, statuscode } = useSelector(
    (state) => state.partyinfo
  );
  const { patientdata } = useSelector((state) => state.patientinfo);
  const [haserror, setHasError] = useState("");
  let userType = Cookies.get("appusertype");

  useEffect(() => {
    /* istanbul ignore next */ setHasError("");
    setAlertflag(false);
    /* istanbul ignore next */

      if (statuscode && statuscode === 200 && (partyvalues.claimno !== "" || partyvalues.firmname !== "")) {
        navigate(`/case-details`, {
          state: {
            caseId: location.state.caseId,
            patientlastname: location.state.patientlastname,
            dobDate: location.state.patientInfomation.dobDate,
            zip: location.state.patientInfomation.zip,
            memberid: location.state.patientInfomation.memberId,
            group: location.state.patientInfomation.groupId,
            insurer: location.state.insurer,
            claimno: partyvalues.claimno,
            firmname: partyvalues.firmname,
            // .replace(/[^\w\s]/gi, "")
            // .replace(/\s+/g, "")
            //.replace(/_/g, ""),
            firmnametext: partyvalues.firmname,
            partytype: userType,
            attorneytype: rbtattorneyfirmname,
            attorneytext: rbtattorneytext,
          },
        });
        // setPartyValues({
        //   firmname: "",
        //   claimno: "",
        // });
      } else if (
        statuscode &&
        statuscode === 204       
      ) {
        /* istanbul ignore next */
        setAlertflag(true);
      } else if (
        statuscode &&
        statuscode === 500         
      ) {
        /* istanbul ignore next */
        setHasError(
          "The application has encountered an unexpected error. Please try again after a short time, or"
        );
      }
    
  }, [statuscode, !isloading]);
  const updatefields = (e) => {
    /* istanbul ignore next */
    setErrParty("");
    /* istanbul ignore next */
    setHasError("");
    /* istanbul ignore next */
    setAlertflag(false);
    if (e.target.name === "claimno") partyvalues.firmname = "";
    if (e.target.name === "firmname") partyvalues.claimno = "";
    /* istanbul ignore next */
    setPartyValues({
      ...partyvalues,
      [e.target.name]: e.target.value,
    });
  };

  const rbAttorneyChange_Handler = (e) => {
    /* istanbul ignore next */
    setHasError("");
    /* istanbul ignore next */
    setErrParty("");
    /* istanbul ignore next */
    setrbtAttorneyfirmname(e.target.value);
    /* istanbul ignore next */
    setrbtAttorneytext(e.currentTarget.attributes[1].value);
  };

  //console.log("location", location);
  const onSubmit_Handler = (e) => {
    /* istanbul ignore next */
    e.preventDefault();
    /* istanbul ignore next */
    setHasError("");
    /* istanbul ignore next */
    setAlertflag(false);
    if (
      (userType?.toLowerCase() === "attorney" ||
        userType?.toLowerCase() === "null") &&
      rbtattorneyfirmname === ""
    ) {
      /* istanbul ignore next */
      setErrParty("Please select Attorney type !!");
    } else if (
      (userType?.toLowerCase() === "attorney" ||
        userType?.toLowerCase() === "null") &&
      rbtattorneyfirmname === "AttFirmName" &&
      (partyvalues.firmname === "" || partyvalues.firmname === null)
    ) {
      /* istanbul ignore next */
      setErrParty("Please enter the firm name !!");
    } else if (
      (userType?.toLowerCase() === "attorney" ||
        userType?.toLowerCase() === "null") &&
      rbtattorneyfirmname === "AttFirstLastName" &&
      (partyvalues.firmname === "" || partyvalues.firmname === null)
    ) {
      /* istanbul ignore next */
      setErrParty("Please enter the attorney first last name !!");
    } else if (
      (userType?.toLowerCase() === "insurance" ||
        userType?.toLowerCase() === "null") &&
      (partyvalues.claimno === "" || partyvalues.claimno === null)
    ) {
      /* istanbul ignore next */
      setErrParty("Please enter the your claim # !!");
    } else {
      /* istanbul ignore next */
      sessionStorage.removeItem("apicallcount");
      /* istanbul ignore next */
      sessionStorage.removeItem("cliamno");
      /* istanbul ignore next */
      sessionStorage.removeItem("firmname");
      /* istanbul ignore next */
      //
      dispatch(
        _submitPartyInformation(
          {
            claimno: partyvalues.claimno,

            firmname: partyvalues.firmname,

            partytype: userType,
            attorneytype: rbtattorneyfirmname,
          },
          {
            dobDate: location.state.patientInfomation.dobDate,
            zip: location.state.patientInfomation.zip,
            memberId: location.state.patientInfomation.memberId,
            groupId: location.state.patientInfomation.groupId,
            caseId: location.state.caseId,
            patientLastName: location.state.patientlastname,
            insurer: location.state.insurer,
          }
        )
      );
    }
  };

  const ShowAttorney = () => {
    return (
      <Form.Group>
        {/* <Form.Label className="font-weight-bold">Attorney type</Form.Label> */}
        <div className="form-check ml-2 mt-2">
          <Form.Check
            value={"AttFirmName"}
            type="radio"
            name={"group3"}
            aria-label="Firm Name"
            onChange={rbAttorneyChange_Handler}
            checked={rbtattorneyfirmname === "AttFirmName"}
          />
          <span
            className="form-check-label font-weight-bold"
            aria-labelledby="AttFirmName"
            htmlFor="AttFirmName"
            style={{ fontSize: "14px" }}
          >
            Firm Name
          </span>
        </div>
        <div className="form-check ml-2 mt-1">
          <Form.Check
            type="radio"
            name={"group4"}
            aria-label="Attorney First Last Name"
            value={"AttFirstLastName"}
            onChange={rbAttorneyChange_Handler}
            checked={rbtattorneyfirmname === "AttFirstLastName"}
          />
          <span
            className="font-weight-bold"
            aria-labelledby="AttFirstLastName"
            htmlFor="AttFirstLastName"
            style={{ fontSize: "14px" }}
          >
            Attorney First Last Name
          </span>
        </div>

        <Form.Control
          value={partyvalues.firmname}
          id="Firm Name"
          type="text"
          name="firmname"
          data-testid="firmname"
          className="form-control form-control-sm mt-2"
          onChange={updatefields}
          onPaste={(e) => {
            /* istanbul ignore next */
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            /* istanbul ignore next */
            e.preventDefault();
            return false;
          }}
        />
        <Form.Text className="small mt-1 mb-0">
          Firm or individual attorney name
        </Form.Text>
        <span className="errmsg">{errParty}</span>
      </Form.Group>
    );
  };
  const ShowInsurance = () => {
    return (
      <Form.Group>
        <Form.Label className="font-weight-bold">Your Claim #</Form.Label>
        <Form.Control
          id="claim"
          type="text"
          value={partyvalues.claimno}
          name="claimno"
          data-testid="claimno"
          className="form-control form-control-sm"
          onChange={updatefields}
          onPaste={(e) => {
            /* istanbul ignore next */
            e.preventDefault();
            return false;
          }}
          onCopy={(e) => {
            /* istanbul ignore next */
            e.preventDefault();
            return false;
          }}
        />
        <span className="errmsg">{errParty}</span>
      </Form.Group>
    );
  };

  return (
    <div>
      <div className="row d-flex justify-content-between mt-2">
        {haserror && <CommonError errormsg={haserror.message} />}

        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Party Information</h1>
            {errmsg && (
              /* istanbul ignore next */ <ErrorValidation
                errormsg={errmsg}
                type="danger"
              />
            )}
            {alertFlag && (
              /* istanbul ignore next */ <AlertValidation type="danger" />
            )}
            <Form
              className="flow-sm"
              onSubmit={onSubmit_Handler}
              autoComplete="off"
            >
              {userType !== "null" &&
              userType !== "undefined" &&
              userType !== ""
                ? userType?.toLowerCase() === "attorney"
                  ? ShowAttorney()
                  : userType?.toLowerCase() === "insurance"
                  ? ShowInsurance()
                  : null
                : null}

              {isloading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm"
                  type="submit"
                  data-testid="btnnext"
                  // onClick={() => navigate(`/case-details`)}
                >
                  Next
                </Button>
              )}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6"></div>
      </div>
    </div>
  );
};

export default PartyVerifier;
