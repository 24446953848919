import sbpServices from "../../../services/sbpServices";
import {
  partyInfoLoadError,
  partyInfoLoadSuccess,
  partyInfoLoadStart,
} from "../SBP/partyInfoActionCreaters";

export const _submitPartyInformation =
  (partyinfofields, patinetinfofields) => async (dispatch) => {
    try {
      /* istanbul ignore next */
      dispatch(partyInfoLoadStart());
      const res = await sbpServices
        ._submitpartyinformation(partyinfofields, patinetinfofields)
        .then((result) => {
          return result;
        });
      /* istanbul ignore next */
      if (res.status === 200) {
        /* istanbul ignore next */
        dispatch(partyInfoLoadSuccess(res.data));
      }
      if (res.status === 204) {       
        dispatch(partyInfoLoadError(res));
      }
    } catch (err) {
      /* istanbul ignore next */
      dispatch(partyInfoLoadError(err.response));
    }
  };
