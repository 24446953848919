import apiClient from "./util/apiClient";

class sbpServices {
  _getcaseInfo = (_patientlastname, _caseid) =>
    apiClient().post("v1/CaseInformation/SearchCase",
    {      
      dobDate: "", zip: "", memberId: "", groupId: "", caseId: _caseid, insurer: "",
      patientLastName: _patientlastname, party: "", attorneyType: "",
      attorneyName: "", claimNumber: ""
    }, 
    {
      headers: {
        "Content-Type": "application/json"               
      },
    });
  _getinsurerList = () =>
    apiClient().get("v1/CaseInformation/Insurers", {
      headers: { "Content-Type": "application/json" },
    });
  _getinsurerInfo = (_patientlastname, insurer) =>
    apiClient().post("v1/CaseInformation/SearchCase",
      {       
        dobDate: "", zip: "", memberId: "", groupId: "", caseId: "", insurer: insurer,
        patientLastName: _patientlastname, party: "", attorneyType: "",
        attorneyName: "", claimNumber: "" 
    },
      {
        headers: {
          "Content-Type": "application/json"
        },
      });
  _submitpatientInfo = ( 
    { dobDate, zip, memberId, groupId },
    { caseId, patientlastname, insurerName }
  ) =>
    apiClient().post("v1/CaseInformation/VerifyPatientInfoOfTheCase",
    {      
      dobDate: dobDate, zip: zip, memberId: memberId, groupId: groupId, caseId: caseId,
      insurer: insurerName, patientLastName: patientlastname, party: "", attorneyType: "",
      attorneyName: "", claimNumber: "" 
    }, {
      headers: {
        "Content-Type": "application/json"
      },
    });

    _getCaseCommuncationMemberDetails = (_caseCommunicationId) =>
    apiClient().get("v1/CaseInformation/Message/"+_caseCommunicationId+"/memberdetails",{
      headers: {
        "Content-Type": "application/json",       
        caseCommunicationId: _caseCommunicationId        
      },
    });

    _getCaseCommuncationDetails = (_caseCommunicationId,_cceid,_userEmailId) =>
    apiClient().get("v1/CaseInformation/Message/"+_caseCommunicationId+"/details?id="+_cceid,{
      headers: {
        "Content-Type": "application/json",
        LoginId : _userEmailId
      },
    });

    _getCaseCommuncationMessageHistory = (_caseCommunicationId,_userEmailId) =>
    apiClient().get("v1/CaseInformation/Message/"+_caseCommunicationId+"/history",{
      headers: {
        "Content-Type": "application/json",       
        LoginId : _userEmailId
      },
    });

    _sendCommunicationReply = (resdata) =>
    apiClient().post("v1/CaseInformation/Message/SendMessageResponse", resdata, {
      headers: {
        "content-type": "application/json",
        caseCommunicationId: resdata.caseCommId,
        insurer: resdata.insurer
      },
    });

    _saveCaseCommunicationUserActivity = (resdata) =>
    apiClient().post("v1/CaseInformation/Message/UserActivity", resdata, {
      headers: {
        "content-type": "application/json"
      },
    });

  /* istanbul ignore next */
  _DownloadCommunicationAttachment = ({
    caseCommId,
    imageId,
    filename,
    fileext
  }) =>
    apiClient().get("v1/CaseInformation/Message/" + caseCommId + "/download/" + imageId , {
      headers: {
        "content-type": "application/json",

      },
      //responseType: "blob",
    }
    );
    
  _submitpartyinformation = (
    { partytype, firmname, claimno, attorneytype },
    { dobDate, zip, memberId, groupId, caseId, patientLastName, insurer }
  ) =>
    apiClient().post("v1/CaseInformation/CaseDetailsByVerifyingPartyInformation",
    { 
      party: partytype,
      attorneyName: firmname,
      claimNumber: claimno,
      dobDate: dobDate,
      zip: zip,
      memberId: memberId,
      groupId: groupId,
      caseId: caseId,
      insurer: insurer,
      patientLastName: patientLastName,
      attorneyType: attorneytype
    }, {
      headers: {
        "Content-Type": "application/json"        
      },
    });
  _uploadfileLOR = (
    mergedpayload   
  ) =>
    apiClient().post("v1/CaseInformation/UploadLetterByCase", mergedpayload,
    //apiClient().post("https://localhost:5084/v1/CaseService/UploadFile", mergedpayload  ,   
    {
      headers: {
        //"Content-Type": "multipart/form-data"   
        "content-type": "application/json",   
      }       
    });

  _Sendresponse = (resdata) =>
    apiClient().post("v1/CaseInformation/SendCaseResponse", resdata, {
      headers: {
        "content-type": "application/json",
        caseId: resdata.caseId,
        insurer: resdata.insurer
      },
    });
  /* istanbul ignore next */
  _Recaptcha_Jwt_AuthToken = (authdata) =>
    apiClient().post("Authorization/ValidateRecaptcha", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });
  /* istanbul ignore next */
  _Invisible_Recaptcha = (authdata) =>
    apiClient().post("Authorization/InvisibleRecaptcha", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });
  /* istanbul ignore next */
  _Jwt_AuthToken = (authdata) =>
    apiClient().post("Authorization/GenerateAccessToken", authdata, {
      headers: {
        "content-type": "application/json",
      },
    });

  /* istanbul ignore next */
  _Download_itemization = ({
    caseNumber,
    caseManagerName,
    caseManagerPhone,
    cliamno,
    partytype,
    firmname,
    attorneytype,
    caseId,
    insurer,
  }) =>
    apiClient().post(
      "v1/CaseInformation/DownloadCaseItemizationDocument",
      { caseNumber: caseNumber,
        caseManagerName: caseManagerName,
        caseManagerPhone: caseManagerPhone,
        claimNumber: cliamno,
        attorneyType: attorneytype,
        attorneyName: firmname,
        party: partytype,
        caseId: caseId,
        insurer: insurer,
        downloadtype:""
      },
      {
        headers: {
          "content-type": "application/json"         
        },
        //responseType: "blob",
      }
    );
  /* istanbul ignore next */
  _Register_user = (reqdata) =>
    apiClient().post(
      "v1/Auth/CreateUser",
      { firstName: reqdata.firstName, lastName : reqdata.lastName,
        email : reqdata.email, appusertype : reqdata.appusertype, mobilePhone: reqdata.mobilePhone },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  /* istanbul ignore next */
  _Account_verification = (token) =>
    apiClient().post(
      "v1/Auth/ActivateUser",
      {},
      {
        headers: {
          "content-type": "application/json",
          userStateToken: token,
        },
      }
    );
  /* istanbul ignore next */
  _Reset_Password = (token, password) =>
    apiClient().post(
      "v1/Auth/CreatePassword",
      {},
      {
        headers: {
          "content-type": "application/json",
          userStateToken: token,
          password: password,
        },
      }
    );
  /* istanbul ignore next */
  _login = (email, password) =>
    apiClient().post(
      "v1/Auth/Login",
      {},
      {
        headers: {
          "content-type": "application/json",
          userName: email,
          password: password,
        },
      }
    );
  /* istanbul ignore next */
  _verify_MFA = (verifydata) =>
  apiClient().post(
    "v1/Auth/VerifyPasscode",verifydata,
    {
      headers: {
        "content-type": "application/json"
      },
    }
  );
  /* istanbul ignore next */
  _resend_MFA = (userStateToken, factorId) =>
    apiClient().get(
      "v1/Auth/ResendPasscode",

      {
        headers: {
          "content-type": "application/json",
          userStateToken,
          factorId,
        },
      }
    );
  /* istanbul ignore next */
  _signOut = (sessionId) =>
    apiClient().delete("v1/Auth/logout", {
      headers: {
        "content-type": "application/json",
        sessionId: sessionId,
      },
    });
  /* istanbul ignore next */
  __sendForgetpasswordemail = (email) =>
    apiClient().get("v1/Auth/ForgotPassword", {
      headers: { "content-type": "application/json", userName: email },
    });
  /* istanbul ignore next */
  _refersh_session = (sid, refreshToken) =>
    apiClient().get("v1/Auth/RefreshSession", {
      headers: {
        "content-type": "application/json",
        sessionId: sid,
        refreshToken: refreshToken
      },
    });

      /* istanbul ignore next */
  _getReactivationLink = (email) =>
  apiClient().get("v1/Auth/GetReactivationLink", {
    headers: { "content-type": "application/json", userName: email },
  });

    /* istanbul ignore next */
    _getAppId = () =>
    apiClient().get("v1/SyncUp/GetAppId", {
      headers: { "content-type": "application/json", appName: "SubroPortal" },
    });
}

export default new sbpServices();
