import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Cookie from "js-cookie";

import LoginContent from "../../common/Content/LoginContent";
import sbpServices from "../../services/sbpServices";
import { LoginAlert } from "../../common/Alert/LoginAlert";
import Loader from "../../common/Loader/Loading";
import { _getSesionwith_MFA } from "../../redux/actions/SBP/caseInformationActions";
import { _onlyNumric } from "../../common/Validation/validation";
import { Alert, Spinner } from "react-bootstrap";

const LoginverifyMFA = () => {
  let navigate = useNavigate(); //redirect to next page
  const dispatch = useDispatch(); //calling redux actions
  const location = useLocation();
  const [showcookiesbanner, setCookiesBanner] = useState(true);
  const [verifycode, setverifyCode] = useState("");
  const [loading, setIsloading] = useState(false);
  const [exError, setExError] = useState("");
  const [extext, setExtext] = useState("");
  const [alertflag, setAlertFlag] = useState(false);
  const [alertexpired, setAlertexpired] = useState(false);
  const [validatmsg, setValidatemsg] = useState("");
  const { info, statuscode, errmsg, isloading } = useSelector(
    (state) => state.caseinfo
  );
  console.log("reuxsdsd", errmsg);
  useEffect(() => {
    /* istanbul ignore next */
    setAlertFlag(false);
    /* istanbul ignore next */
    setExError("");
    setAlertexpired(false);
    /* istanbul ignore next */
    if (statuscode !== null) {
      /* istanbul ignore next */
      if (statuscode === 200 || statuscode === 201) {
        if(info.message === "PASSWORD_EXPIRED")
        {           
          /* istanbul ignore next */
          navigate(`/forgetpasswordemail`, {
            state: { isPasswordExpired: true },
          });
        }
        else{
        /* istanbul ignore next */
        Cookie.set("useremailid", location.state.userEmailId);
        Cookie.set("username", info.loginName);
        Cookie.set("sessiontoken", info.sessionToken);
        Cookie.set("TokenExpTime", info.sessionTokenExpireIn);
        Cookie.set("sessionId", info.sessionId);
        Cookie.set("refreshToken", info.refreshToken);
        Cookie.set("accessToken", info.accessToken);
        Cookie.set("accessTokenExpiresIn", info.accessTokenExpiresIn);
        Cookie.set("appusertype", info.userType);
        Cookie.remove("statetoken");
        /* istanbul ignore next */
        navigate("/case-information");
        /* istanbul ignore next */
        setExtext("");
        }
      } else if (errmsg !== null && errmsg.statusCode === 403 ) {
        /* istanbul ignore next */
        setExError("Invalid code. Please enter valid verification code.");
        /* istanbul ignore next */
        setExtext("");
      } else if (errmsg !== null && errmsg.statusCode === 419) {
        /* istanbul ignore next */
        //setExError("Verification code expired.");
        /* istanbul ignore next */
        //  setExtext(
        //  "Please click on resend code button to generate new verification code."
        // );
        /* istanbul ignore next */ setAlertexpired(true);
        // setAlertFlag(true);
      } else if (errmsg !== null) {
        /* istanbul ignore next */
        setExtext("");
        setExError(
          `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
        );
      }
    }
  }, [statuscode]);
  //console.log();
  const verify_Handler = (e) => {
    /* istanbul ignore next */
    e.preventDefault();
    /* istanbul ignore next */
    setValidatemsg("");
    /* istanbul ignore next */ setAlertexpired(false);
    if (verifycode.trim() === "" || verifycode.trim() === null) {
      /* istanbul ignore next */ setValidatemsg(
        "Please enter verification code."
      );
    } else {
      /* istanbul ignore next */
      setExError("");
      // setIsloading(true);
      /* istanbul ignore next */
      /* istanbul ignore next */ dispatch(
        _getSesionwith_MFA({
          userStateToken : location.state.token,
          passcode : verifycode,
          factorId : location.state.factorId,
          userName : location.state.userEmailId,
          password : location.state.password
        })
      );
    }
  };

  const resend_Hander = (e) => {
    /* istanbul ignore next */ e.preventDefault();
    /* istanbul ignore next */ setAlertFlag(false);
    /* istanbul ignore next */ setExError("");

    /* istanbul ignore next */ setExtext("");
    /* istanbul ignore next */ setIsloading(true);
    setAlertexpired(false);
    sbpServices
      ._resend_MFA(location.state.token, location.state.factorId)
      .then((res) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (res.status === 200 || res.status === 201) {
          /* istanbul ignore next */ setAlertFlag(true);
          /* istanbul ignore next */ setExError("");
        } else {
          /* istanbul ignore next */
          setExError(res.data.statusText);
        }
      })
      .catch((err) => {
        /* istanbul ignore next */
        setIsloading(false);
        /* istanbul ignore next */
        if (err.response.status === 403) {
          /* istanbul ignore next */ setExError(
            "Invalid code. Please enter valid verification code."
          );
        } else if (err.response.status === 401) {
          // /* istanbul ignore next */ setExError(
          //   "Verification code expired. Please click to login again."
          // );
          /* istanbul ignore next */ setAlertexpired(true);
          // confirmAlert({
          //   title: "Alert",
          //   message: "Token expired. Please click ok to login again.",
          //   buttons: [
          //     {
          //       label: "Ok",
          //       onClick: () => navigate("/"),
          //     },
          //   ],
          //   closeOnEscape: false,
          //   closeOnClickOutside: false,
          // });
        } else {
          setExError(
            `The application has encountered an unexpected error. Please try again after a short time, or you can contact us via email or call ${process.env.REACT_APP_SUPPORT_PHONE} for assistance.`
          );
        }
        //setExError("Opps Something went wrong...");
      });
  };

  const passcode_handler = (e) => {
    e.preventDefault();
    /* istanbul ignore next */ setExError("");
    /* istanbul ignore next */ setAlertFlag(false);
    /* istanbul ignore next */ setExtext("");
    /* istanbul ignore next */ setValidatemsg("");
    /* istanbul ignore next */ setverifyCode(e.target.value);    
  };

  let expiredmsg = (
    <Alert variant="danger">
      {" "}
      <p
        className="pt-1 small mb-0"
        data-testid="alertmsgcontainer"
        style={{ display: "contents" }}
      >
        Verification code expired. Please click here to{" "}
        <strong>
          <Link to="/">Sign In</Link>
        </strong>
        {/* Please check your email inbox to activate your account. */}
      </p>
    </Alert>
  );

  return (
    <>
      {/* <BrowerNotSupport num={2} /> */}
      <div className="row d-flex justify-content-between mt-2 createuser">
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Verification</h1>
            {alertexpired && expiredmsg}
            {exError !== "" ? (
              /* istanbul ignore next */ <LoginAlert
                type={"danger"}
                errmsg={exError}
                formattext={extext}
              />
            ) : null}
            {alertflag && (
              /* istanbul ignore next */ <LoginAlert
                type={"success"}
                errmsg="Please check your email for new verification code."
                //formattext={extext}
              />
            )}

            <Form
              className="flow-sm"
              ///onSubmit={verify_Handler}
              autoComplete="off"
            >
              <Form.Group>
                <Form.Label className="font-weight-bold" id="passcode">
                  Verification code
                </Form.Label>
                <Form.Control
                  id="passcode"
                  data-testid="passcode"
                  type="text"
                  required
                  aria-labelledby="passcode"
                  className="form-control form-control-sm"
                  value={verifycode}
                  onKeyPress={(e) => _onlyNumric(e)}
                  minLength="6"
                  maxLength="6"
                  onChange={passcode_handler}
                />
                <Form.Text className="small mt-0 mb-0">
                  Enter the Code from the email we just sent you.
                </Form.Text>
                <span className="errmsg">{validatmsg}</span>
              </Form.Group>

              {isloading && isloading ? (
                <Loader />
              ) : (
                <div>
                  <Button
                    variant="primary"
                    className="btn btn-success btn-sm g-recaptcha"
                    type="submit"
                    data-testid="btnverify"
                    onClick={verify_Handler}
                  >
                    Verify
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <p className="loginbold">
            Verify you received the code on email and proceed to login.
          </p>
          <p className="loginbold mb-1">Need a new code?</p>
          <p className="">
            Request a new code.{"   "}&nbsp;&nbsp;&nbsp;
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="btnloading"
              />
            ) : (
              <Button
                variant="primary"
                className="btn btn-success btn-sm g-recaptcha"
                type="submit"
                // style={{ float: "right" }}
                data-testid="btnnext"
                onClick={resend_Hander}
              >
                Resend code
              </Button>
            )}
          </p>
          <br />
          <br />

          <p className="mb-1">
            Trouble signing-in? Please try again after some time.{" "}
            <Link to="/">Sign in</Link>
          </p>
          <p className="">
            Or contact us{" "}
            <a
              href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_EMAIL_SUBJECTS}`}
            >
              via email
            </a>{" "}
            or call {`${process.env.REACT_APP_SUPPORT_PHONE}`} for assistance.
          </p>
          {/* <LoginContent link={false} />{" "} */}
        </div>
      </div>
    </>
  );
};

export default LoginverifyMFA;
