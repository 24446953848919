import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import CaseInsurerInformationContent from "../../common/Content/CaseInsurerContent";
import AlertValidation from "../../common/Alert/AlertValidation";
import {
  _getInsurerName,
  _getInsurerList,
} from "../../redux/actions/SBP/insurerActions";
import Select from "../../common/Select/Select";
import { _patientLastNamespecialchar } from "../../common/Validation/validation";
import ErrorValidation from "../../common/Alert/ErrorValidation";
import Cookie from "js-cookie";
import Loader from "../../common/Loader/Loading";

import sbpServices from "../../services/sbpServices";
import CommonError from "../../common/Alert/CommonError";
const InsurerName = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [insurer, setInsurer] = useState("0");

  //recapcha

  const refRecaptcha = useRef(null);

  //const [insurerList, setInsurerList] = useState([]);
  const [alertflag, setAlertflag] = useState(false);
  const [patientlastname, setPatientlastname] = useState("");
  const [haserror, setHasError] = useState("");
  //validation
  const [errpatientlastname, setErrpatientlastname] = useState("");
  const [errinsurer, setErrinsurer] = useState("");

  //selector values from redux
  const { isloading, errmsg, insurerlist, statuscode, insurerloader } =
    useSelector((state) => state.insurerinfo);

  useEffect(() => {
    //using redux

    if (statuscode && statuscode === 200 && insurer !== "0") {
      /* istanbul ignore next */
      navigate(`/patient-information-alt`, {
        state: { patientlastname, insurer },
      });
    } else if (statuscode && statuscode === 204) {
      /* istanbul ignore next */
      setAlertflag(true);
    } else if (statuscode === null){
      fnbindInsurerList();
    }
  }, [statuscode]);

  /* istanbul ignore else */
  const onsubmitHanlder = async (e) => {
    e.preventDefault();
    setAlertflag(false);
    // Cookie.remove("Token");
    // Cookie.remove("TokenExpTime");
    if (patientlastname === "") {
      setErrpatientlastname("Please enter patient last name !!");
      //refRecaptcha.current.reset();
    } else if (insurer === "0") {
      setErrinsurer("Please select insurer !!");
    } else {
      /* istanbul ignore next */
      await dispatch(_getInsurerName(patientlastname, insurer));
    }
  };

  //bind insurer list with axios
  async function fnbindInsurerList() {
    /* istanbul ignore next */
    dispatch(_getInsurerList());
  }

  const insurerChange_Handler = (e) => {
    /* istanbul ignore next */
    setErrinsurer("");
    /* istanbul ignore next */
    setInsurer(e.target.value);
  };

  return (
    <>
      <div className="row d-flex justify-content-between mt-2">
        {haserror && <CommonError errormsg={haserror.message} />}
        <div className="col-md-7 col-lg-5 mb-4">
          <div className="bg-light p-4 border mr-0 mr-md-3 mr-lg-4">
            <h1 className="mpi-hdr-3 mb-3">Case Information</h1>
            { errmsg && <ErrorValidation errormsg={errmsg} type="danger" />}
            {alertflag && <AlertValidation type="danger" />}
            <Form
              className="flow-sm"
              onSubmit={onsubmitHanlder}
              autoComplete="off"
            >
              <Form.Group className="">
                <Form.Label className="font-weight-bold">
                  Patient Last Name
                </Form.Label>
                <Form.Control
                  id="patient-name"
                  data-testid="pname"
                  type="text"
                  required
                  className="form-control form-control-sm"
                  value={patientlastname}
                  onKeyPress={(e) => _patientLastNamespecialchar(e)}
                  onChange={(e) => setPatientlastname(e.target.value)}
                  onPaste={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    /* istanbul ignore next */
                    e.preventDefault();
                    return false;
                  }}
                />
                <span className="errmsg">{errpatientlastname}</span>
                <Form.Text className="small mt-1 mb-0">
                  Last name as it appears on letter
                </Form.Text>
              </Form.Group>

              <Form.Group className="">
                <Form.Label className="font-weight-bold">Insurer</Form.Label>
                {insurerloader ? (
                  <Loader />
                ) : (
                  <Select
                    onChange_Handler={insurerChange_Handler}
                    val={insurer}
                    List={insurerlist && insurerlist.insurer}
                  />
                )}
                <span className="errmsg">{errinsurer}</span>
              </Form.Group>

              {isloading ? (
                <Loader />
              ) : (
                <Button
                  variant="primary"
                  className="btn btn-success btn-sm"
                  type="submit"
                  aria-label="btnnext"
                  data-testid="btnnext"
                >
                  Next
                </Button>
              )}
            </Form>
          </div>
        </div>
        <div className="col-md-5 col-lg-6">
          <CaseInsurerInformationContent content="insurer" />
        </div>
      </div>
    </>
  );
};

export default InsurerName;
