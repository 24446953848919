import React, { useState, useRef } from "react";

import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import sbpServices from "../../services/sbpServices";
import Spinner from "react-bootstrap/Spinner";
import UncaughtError from "../../common/Alert/UncaughtError";
const SendResponse = ({ isnonphi }) => {
  const location = useLocation();
  const [textarea, setTextArea] = useState("");
  const [showText, setShowText] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [errTextarea, seterrTextarea] = useState("");
  const [haserror, setHasError] = useState("");
  const [loading, setLoading] = useState(false);

  /* istanbul ignore next */
  const Text = () => (
    <span
      id="confirm-response"
      data-testid="resmsg"
      className="alert alert-success icon btn-responce"
    >
      {" "}
      Response sent
    </span>
  );
  /* istanbul ignore next */
  const handleControl = () => {
    setShowText(false);
    setDisable(false);
    seterrTextarea("");
    setHasError("");
  };
  /* istanbul ignore next */
  const _responce_Send = (e) => {
    e.preventDefault();
    setHasError("");
    if (textarea.trim() === null || textarea.trim() === "") {
      seterrTextarea("Please enter the response message !!");
    } else {
      setLoading(true);
      fnsendresponse();
    }

    function fnsendresponse() {
      sbpServices
        ._Sendresponse({
          caseId: location.state.caseId,
          insurer: location.state.insurer,
          patientLastName: location.state.patientlastname,
          zip: location.state.zip,
          dobDate: location.state.dobDate,
          groupId: location.state.group,
          memberId: location.state.memberid,
          party: location.state.partytype,
          //attorneyName: location.state.firmname,
          attorneyName:
            sessionStorage.getItem("firmname") === null ||
            sessionStorage.getItem("firmname") === ""
              ? location.state.firmname
              : sessionStorage.getItem("firmname"),
          /// claimNumber: location.state.claimno,
          claimNumber:
            sessionStorage.getItem("cliamno") === null ||
            sessionStorage.getItem("cliamno") === ""
              ? location.state.claimno
              : sessionStorage.getItem("cliamno"),
          attorneytype: location.state.attorneytype,
          responseMessage: textarea,
        })
        .then((res) => {
          /* istanbul ignore if */
          if (res.status === 201 || res.status === 200) {
            setLoading(false);
            setShowText(true);
            setTextArea(" ");
            setDisable(true);
          } /* istanbul ignore else */ else {
            setLoading(false);
            setHasError("error");
          }
        })
        .catch((err) => {
          setLoading(false);
          setHasError(err);
        });
    }
  };

  return (
    <div>
      {" "}
      <div id="response-form" className="form-group">
        <Form.Label className="font-weight-bold small" data-testid="SendRes">
          Send a Response
        </Form.Label>
        <p className="small mb-2">
          Missing or incorrect information? Let us know by sending a response.
        </p>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            data-testid="SendResTextArea"
            rows={3}
            value={textarea}
            limit={1000}
            maxLength="1000"
            onChange={(e) => setTextArea(e.target.value)}
            onClick={handleControl}
          />
        </Form.Group>
        <span className="errmsg">{errTextarea}</span>
        <p className="small text-right mb-0">
          <span id="count">{1000 - textarea.length}</span> of 1000 characters
          remaining
        </p>
        <button
          className="btn  btn-success btn-sm"
          data-testid="sendresbtn"
          onClick={_responce_Send}
          disabled={isnonphi === null ? true : disable}
        >
          Send Response
        </button>{" "}
        {showText ? <Text /> : null}
        {loading && (
          <Spinner
            animation="border"
            variant="primary"
            className="btnloading"
          />
        )}
        {haserror && <UncaughtError />}
      </div>
    </div>
  );
};

export default SendResponse;
